import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLoaderCircleComponent } from '../p-loader-circle/p-loader-circle.component';

@Component({
	selector: 'p-button',
	standalone: true,
	imports: [CommonModule, PLoaderCircleComponent],
	templateUrl: './p-button.component.html',
	styleUrls: ['./p-button.component.css']
})
export class PButtonComponent {
	/**
	 * Tipo del botón. personalizado color'.
	 * Por defecto, el tipo es 'primary'.
	 */
	@Input() loader = false;
	/**
	 * Tipo del botón. personalizado color'.
	 * Por defecto, el tipo es 'primary'.
	 */
	@Input() type: 'primary' | 'secondary' | 'tertiary' = 'primary';
	/**
	 * Puede ser 'large', 'medium', 'small' o 'x-small'.
	 * Por defecto, el tamaño es 'large'.
	 */
	@Input() size: 'large' | 'medium' | 'small' | 'x-small' = 'large';

	/**
	 * Establece un ancho personalizado al boton.
	 * El elemento `w-*` de TailwindCSS
	 */
	@Input() width = 'w-full';

	@Output() clickEvent = new EventEmitter<void>();

	/**
	 * Devuelve las clases CSS generadas para el botón basado en el tipo y el tamaño especificados.
	 * @returns Clases CSS del botón.
	 */
	get buttonClasses() {
		// return `btn-${this.type} lg:${this.sizeLg} md:${this.sizeMd} sm:${this.sizeSm}`;
		return `btn-${this.type} btn-${this.size} flex justify-center items-center ${this.width}`;
	}

	handleClick(): void {
		this.clickEvent.emit();
	}
}
